exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-category-name-index-tsx": () => import("./../../../src/pages/{Category.name}/index.tsx" /* webpackChunkName: "component---src-pages-category-name-index-tsx" */),
  "component---src-pages-category-name-installed-tsx": () => import("./../../../src/pages/{Category.name}/installed.tsx" /* webpackChunkName: "component---src-pages-category-name-installed-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installed-tsx": () => import("./../../../src/pages/installed.tsx" /* webpackChunkName: "component---src-pages-installed-tsx" */),
  "component---src-pages-latest-tsx": () => import("./../../../src/pages/latest.tsx" /* webpackChunkName: "component---src-pages-latest-tsx" */),
  "component---src-pages-snap-snap-location-snap-slug-tsx": () => import("./../../../src/pages/snap/{Snap.location}/{Snap.slug}.tsx" /* webpackChunkName: "component---src-pages-snap-snap-location-snap-slug-tsx" */)
}

